// 
import Link from "next/link";
import { Assets } from "../list/Assets";
// 
export var menuIn = false;
export var searchIn = false;

export var searchInValue = function (value) {
  menuIn = value;
};

export var bothValue = function (value) {
  menuIn = value;
  searchIn = value;
};

export default function Logocom(props) {
  function search_in() {
    var Search_div = document.getElementsByClassName("Search_div");
    var black_screen = document.getElementById("black_screen");
    var menu = document.getElementsByClassName("menu");

    //menu_check();
    document.body.style.overflow = "hidden";
    Search_div[0].classList.remove("Sout");
    Search_div[0].classList.add("Sin");
    black_screen.style.display = "block";
    menu[0].className = "menu out";
    searchIn = true;
    menuIn = false;
  }

  function dropDown() {
    var logo_down = document.getElementsByClassName("logo-down");
    var menu = document.getElementsByClassName("menu");
    var black_screen = document.getElementById("black_screen");

    if (menuIn) {
      menuIn = false;
      menu[0].className = "menu out";
      setTimeout(function () {
        menu[0].scrollTop = 0;
      }, 250);
      document.body.style.overflow = "auto";
      black_screen.style.display = "none";
      logo_down[0].className = "logo-down down backtodown";
    } else {
      black_screen.style.display = "block";
      menu[0].className = "menu fade";
      document.body.style.overflow = "hidden";
      menuIn = true;
      logo_down[0].className = "logo-down up";
    }
  }

  function closeMenu() {
    var logo_down = document.getElementsByClassName("logo-down");
    var menu = document.getElementsByClassName("menu");
    var black_screen = document.getElementById("black_screen");
    menuIn = false;
    menu[0].className = "menu out";
    document.body.style.overflow = "auto";
    black_screen.style.display = "none";
    logo_down[0].className = "logo-down down backtodown";
  }

  function closeMenuFast() {
    var logo_down = document.getElementsByClassName("logo-down");
    var menu = document.getElementsByClassName("menu");
    var black_screen = document.getElementById("black_screen");
    menuIn = false;
    menu[0].className = "menu out2";
    document.body.style.overflow = "auto";
    black_screen.style.display = "none";
    logo_down[0].className = "logo-down down";
  }
  return (
    <div id="logo-container1">
      <div className="logo-flex">
        <nav className="logo-thumb">
          <Link href="/" prefetch={false}>
            <a className="logo" onClick={closeMenu}>
              <img
                width="198px"
                height="101px"
                decoding="async"
                alt="logo"
                title="logo"
                src={Assets[0].logo}
              />
            </a>
          </Link>
          <div className="logo-btns" prefetch={false}>
            <Link href="/">
              <a className="logo-home" onClick={closeMenu}>
                <img
                  width="40px"
                  height="40px"
                  decoding="async"
                  alt="Home Icon"
                  title="Home"
                  src={Assets[0].home}
                />
              </a>
            </Link>
            <div className="logo-down down" onClick={dropDown} >
              <img
                width="40px"
                height="40px"
                decoding="async"
                alt="Drop down icon"
                title="Drop down icon"
                src={Assets[0].drop_down_icon}
              />
            </div>
          </div>

          <div className="menu out">
            <div className="list">
              <div className="items" title="search" onClick={search_in}>
                <img
                  decoding="async"
                  alt="search icon"
                  title="search icon"
                  width="28px"
                  height="28px"
                  src={Assets[0].searchIcon}
                ></img>
                <div className="menutext">Search</div>
              </div>
              <Link href={"/categories/exclusive-games"} prefetch={false}>
                <a className="items" title="Exclusive Games" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].exclusiveIcon}
                  ></img>
                  <div className="menutext">Exclusive Games</div>
                </a>
              </Link>
              <Link href={"/categories/princess-games"} prefetch={false}>
                <a className="items" title="Princess Games" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].princessIcon}
                  ></img>
                  <div className="menutext">Princess Games</div>
                </a>
              </Link>
              <Link href={"/categories/dress-up-games"} prefetch={false}>
                <a className="items" title="Dress Up Games" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].dressUpGames}
                  ></img>
                  <div className="menutext">Dress Up Games</div>
                </a>
              </Link>
              <Link href={"/categories/makeover-games"} prefetch={false}>
                <a className="items" title="Makeover Games" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].makeoverGames}
                  ></img>
                  <div className="menutext">Makeover Games</div>
                </a>
              </Link>
              <Link href={"/categories/decoration-games"} prefetch={false}>
                <a className="items" title="Decoration Games" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].decorationGames}
                  ></img>
                  <div className="menutext">Decoration Games</div>
                </a>
              </Link>
              <Link href={"/categories/wedding-games"} prefetch={false}>
                <a className="items" title="Wedding Games" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].weddingGames}
                  ></img>
                  <div className="menutext">Wedding Games</div>
                </a>
              </Link>
              <Link href={"/categories/celebrity-games"} prefetch={false}>
                <a className="items" title="Celebrity Games" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].celebrityGames}
                  ></img>
                  <div className="menutext">Celebrity Games</div>
                </a>
              </Link>
              <Link href={"/categories/cooking-games"} prefetch={false}>
                <a className="items" title="Cooking Games" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].cookingGames}
                  ></img>
                  <div className="menutext">Cooking Games</div>
                </a>
              </Link>
              <Link href={"/categories/doctor-games"} prefetch={false}>
                <a className="items" title="doctorGames" onClick={closeMenuFast}>
                  <img
                    decoding="async"
                    alt="search icon"
                    title="search icon"
                    width="28px"
                    height="28px"
                    src={Assets[0].doctorGames}
                  ></img>
                  <div className="menutext">Doctor Games</div>
                </a>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}