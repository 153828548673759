import { bothValue } from "./Logocom";
export default function BlackScreen() {
  function balckScreenClick() {
    var Search_div = document.getElementsByClassName("Search_div");
    var black_screen = document.getElementById("black_screen");
    var menu = document.getElementsByClassName("menu");
    var logo_down = document.getElementsByClassName("logo-down");
    document.body.style.overflow = "auto";
    Search_div[0].className = "Search_div Sout";
    black_screen.style.display = "none";
    menu[0].className = "menu out";
    bothValue(false);
    logo_down[0].className="logo-down down backtodown";
  }

  return (
    <div
      id="black_screen"
      style={{ display: "none" }}
      onClick={balckScreenClick}
    ></div>
  );
}
